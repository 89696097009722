<script>
	import { onMount } from "svelte";
	import { getGameSessionId, getParams, assets, cssVariables } from "../services/utils";
	import CreateScreen from "./CreateScreen.svelte";
	import JoinScreen from "./JoinScreen.svelte";

	let page = "CreateScreen";

	const bg = `url(${assets[process.env.APP_ENV][getParams("game")].bg})`
	let changeToJoinPage = p => {
		page = p;
	};

	onMount(() => {
		if (getGameSessionId() === null) {
			page = "CreateScreen";
		} else {
			page = "JoinScreen";
		}
	});
</script>

<div use:cssVariables={{bg}} class="background" />

<div class="container">
	{#if page === "CreateScreen"}
		<CreateScreen {changeToJoinPage} />
	{:else if page === "JoinScreen"}
		<JoinScreen {changeToJoinPage} />
	{:else}
		<div>Please Refresh</div>
	{/if}
</div>

<style>
	.container {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
	}
	.background {
		position: absolute;
		background-image: var(--bg);
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
</style>
