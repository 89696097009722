<script>
	import { onMount } from "svelte";
	import { nanoid } from "nanoid";
	import { v4 as uuidv4 } from "uuid"
	import { dbRoot } from "../services/database";
	import { identity, roomId, sessionId } from "../services/store";
	import { getParams, assets, cssVariables } from "../services/utils";

	export let changeToJoinPage = () => {};
	const color = assets[process.env.APP_ENV][getParams("game")].color

	let CreateRoom = async () => {
		localStorage.setItem("userId", $identity);
		dbRoot.child(`${$roomId}+${$sessionId}`).set({
			roomCreated: true,
			createdBy: $identity,
			joinedGame: false,
		});

		window.parent.postMessage(`${$roomId}.${$sessionId}`, assets[process.env.APP_ENV][getParams("game")].parentUrl)
		changeToJoinPage("JoinScreen");
	};

	onMount(() => {
		$identity = uuidv4() + "-" + nanoid();
		$roomId = nanoid();
		$sessionId = nanoid();
	});
</script>

<div class="container">
	<a href="https://www.letsdive.io/" target="_blank">
		<img src="/assets/svg/logo.svg" alt="company logo" class="companyLogo" />
	</a>
	<div class="innerContainer">
		<img class="logo" src={assets[process.env.APP_ENV][getParams("game")].logo} alt="logo" />
		<button use:cssVariables={{color}} on:click={CreateRoom} class="button">Create a room</button>
	</div>
</div>

<style>
	.container {
		width: calc(100% - 32px);
		height: 100%;
		margin: 0 16px;
		position: relative;
	}
	.innerContainer {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: absolute;
	}
	.companyLogo {
		top: 48px;
		left: 48px;
		position: absolute;
		z-index: 10;
	}
	.logo {
		width: 100%;
		height: auto;
		max-width: 429px;
		max-height: 175px;
		padding: 0;
		margin: 0 0 40px 0;
	}
	.button {
		width: 218px;
		height: 52px;
		background: #ffffff;
		box-shadow: 0px 8px 0px rgba(102, 102, 102, 0.75);
		border-radius: 58px;
		font-family: Manrope;
		font-style: normal;
		font-weight: 800;
		font-size: 18px;
		line-height: 24px;
		text-align: center;
		color: var(--color);
		cursor: pointer;
	}
	@media only screen and (max-width: 550px) {
		.companyLogo {
			top: 48px;
			left: 50%;
			transform: translate(50%);
		}
	}
</style>
