import "@sentry/browser";
import App from "./App.svelte";
// if (!process.env.EMULATE) {
// 	console.log("sentry connected");
// 	Sentry.init({
// 		dsn: "Put your dsn here",
// 		environment: process.env.APP_ENV,
// 		// Set tracesSampleRate to 1.0 to capture 100%
// 		// of transactions for performance monitoring.
// 		// We recommend adjusting this value in production
// 		tracesSampleRate: 1.0,
// 	});
// }
const app = new App({
    target: document.body,
    props: {},
});
export default app;
