import { dbRoot } from "./database";
export const params = new URLSearchParams(window.location.search);

export const getParams = param => {
	if (params.get(param) === null || params.get(param) === "null" || params.get(param) === "") {
		if (param === "game") {
			return "trivia";
		}
		return null;
	}
	return `${params.get(param)}`;
};

export const getGameSessionId = function () {
	const roomId = getParams("roomId");
	const sessionId = getParams("sessionId");
	if (roomId == null || sessionId === null) {
		return null;
	}
	return `${roomId}+${sessionId}`;
};

export const simpleIconsOrg = [
	"musicbrainz",
	"githubsponsors",
	"veepee",
	"statamic",
	"yoast",
	"triller",
	"starship",
	"jamstack",
	"sass",
	"ideal",
	"t-mobile",
	"consul",
	"prestashop",
	"aurelia",
	"awesomelists",
	"bbciplayer",
	"accusoft",
	"codecov",
	"disroot",
	"foodpanda",
	"azureartifacts",
	"dribbble",
	"hugo",
	"gitter",
	"protondb",
	"storybook",
	"styled-components",
	"processwire",
	"vox",
	"lg",
	"asciidoctor",
	"man",
	"vfairs",
	"erlang",
	"raspberrypi",
	"p5.js",
	"songkick",
	"zigbee",
	"foursquare",
	"adobeindesign",
	"invision",
	"bugatti",
	"a-frame",
	"polymerproject",
	"sparkar",
	"fiat",
	"nissan",
	"chupachups",
	"nestjs",
	"strongswan",
	"apacheecharts",
	"commonworkflowlanguage",
	"marriott",
	"audi",
	"affinitypublisher",
	"iheartradio",
	"angular",
	"ardour",
	"slides",
	"apacheflink",
	"openstack",
	"codefactor",
	"csswizardry",
	"mtr",
	"sanfranciscomunicipalrailway",
	"broadcom",
	"instagram",
	"rtl",
	"alfaromeo",
	"debian",
	"guangzhoumetro",
	"apachemaven",
	"adblockplus",
	"powers",
	"themighty",
	"askfm",
	"hitachi",
	"meetup",
	"hotjar",
	"vivino",
	"stadia",
	"steinberg",
	"beatsbydre",
	"autotask",
	"fujifilm",
	"vulkan",
	"playerfm",
	"kyocera",
	"honda",
	"hackclub",
	"pocket",
	"vauxhall",
	"tutanota",
	"pinterest",
	"jest",
	"rewe",
];

export const assets = {
	dev: {
		trivia: {
			bg: "/assets/svg/trivia_bg.svg",
			logo: "/assets/svg/trivia.svg",
			color: "#5a42f0",
			parentUrl: "https://trivia-dev.letsdive.io",
			gameUrl: "https://trivia-dev.da.letsdive.io",
		},
		ttol: {
			bg: "/assets/svg/ttol_bg.svg",
			logo: "/assets/svg/ttol.svg",
			color: "#5a42f0",
			parentUrl: "https://ttol-dev.letsdive.io",
			gameUrl: "https://ttol-dev.da.letsdive.io",
		},
		scribbl: {
			bg: "/assets/svg/scribbl_bg.svg",
			logo: "/assets/svg/scribbl.svg",
			color: "#5a42f0",
			parentUrl: "https://scribbl-dev.letsdive.io",
			gameUrl: "https://dpt2-dev.da.letsdive.io/",
		},
		wos: {
			bg: "/assets/svg/wos_bg.svg",
			logo: "/assets/svg/wos.svg",
			color: "#5a42f0",
			parentUrl: "https://wos-dev.letsdive.io",
			gameUrl: "https://wos-dev.da.letsdive.io",
		},
		bingo: {
			bg: "/assets/svg/bingo_bg.svg",
			logo: "/assets/svg/bingo.svg",
			color: "#5a42f0",
			parentUrl: "https://bingo-dev.letsdive.io",
			gameUrl: "https://bingo-dev.da.letsdive.io",
		},
	},
	prod: {
		trivia: {
			bg: "/assets/svg/trivia_bg.svg",
			logo: "/assets/svg/trivia.svg",
			color: "#5a42f0",
			parentUrl: "https://main-trivia-prod.web.app",
			gameUrl: "https://trivia-prod.da.letsdive.io",
		},
		ttol: {
			bg: "/assets/svg/ttol_bg.svg",
			logo: "/assets/svg/ttol.svg",
			color: "#5a42f0",
			parentUrl: "https://main-ttol-prod.web.app",
			gameUrl: "https://ttol-prod.da.letsdive.io",
		},
		scribbl: {
			bg: "/assets/svg/scribbl_bg.svg",
			logo: "/assets/svg/scribbl.svg",
			color: "#5a42f0",
			parentUrl: "https://main-scribbl-prod.web.app",
			gameUrl: "https://dpt2-prod.da.letsdive.io/",
		},
		wos: {
			bg: "/assets/svg/wos_bg.svg",
			logo: "/assets/svg/wos.svg",
			color: "#5a42f0",
			parentUrl: "https://main-wos-prod.web.app",
			gameUrl: "https://wos-prod.da.letsdive.io",
		},
		bingo: {
			bg: "/assets/svg/bingo_bg.svg",
			logo: "/assets/svg/bingo.svg",
			color: "#5a42f0",
			parentUrl: "https://main-bingo-prod.web.app",
			gameUrl: "https://bingo-prod.da.letsdive.io",
		},
	},
};

export function cssVariables(node, variables) {
	setCssVariables(node, variables);

	return {
		update(variables) {
			setCssVariables(node, variables);
		},
	};
}
function setCssVariables(node, variables) {
	for (const name in variables) {
		node.style.setProperty(`--${name}`, variables[name]);
	}
}
