<script>
	import { onMount } from "svelte";
	import { dbRoot } from "../services/database";
	import { getGameSessionId, getParams, assets, simpleIconsOrg, cssVariables } from "../services/utils";
	import { identity, roomId, sessionId } from "../services/store";

	export let changeToJoinPage = () => {};
	let roomToJoin,
		sessionToJoin,
		createdBy,
		localUserId,
		displayRoom,
		userProfilePicture,
		roomLink,
		gameLink,
		disabled,
		isHost = false,
		name = "",
		btnText = "Copy room link";

	$: roomLink = `${assets[process.env.APP_ENV][getParams("game")].parentUrl}/?room=${roomToJoin}.${sessionToJoin}`;

	$: userProfilePicture = ``;

	$: if (name === "" || name === undefined) {
		disabled = true;
	} else {
		disabled = false;
	}

	$: if (name?.length > 0) {
		gameLink = `${assets[process.env.APP_ENV][getParams("game")].gameUrl}/?isHost=${
			isHost ? "true" : "false"
		}&userName=${name}&roomId=${roomToJoin}&userProfilePicture=${userProfilePicture}&userId=${$identity}&teamId=daGameCreator&sessionId=${sessionToJoin}`;
		localStorage.setItem("name", name);
	}

	const randpop = array => array.splice(Math.floor(Math.random() * array.length), 1);
	const color = assets[process.env.APP_ENV][getParams("game")].color;

	const copyLinkToCB = () => {
		navigator.clipboard.writeText(roomLink).then(
			function () {
				btnText = "Link Copied!";
				let interval = setInterval(() => {
					btnText = "Copy room link";
					clearInterval(interval);
				}, 3000);
			},
			function (error) {
				console.error(error);
			}
		);
	};

	const goToGame = async () => {
		await dbRoot
			.child(`${roomToJoin}+${sessionToJoin}`)
			.once("value")
			.then(val => {
				let value = val.val();
				if (value.joinedGame === false) {
					dbRoot.child(`${roomToJoin}+${sessionToJoin}`).child("joinedGame").set(true);
					isHost = true;
				} else {
					isHost = false;
				}
			});
		window.location.replace(gameLink);
	};

	onMount(() => {
		if (getGameSessionId() !== null) {
			[roomToJoin, sessionToJoin] = getGameSessionId().split("+");
		} else {
			roomToJoin = $roomId;
			sessionToJoin = $sessionId;
		}
		dbRoot
			.child(`${roomToJoin}+${sessionToJoin}`)
			.once("value")
			.then(val => {
				let value = val.val();
				if (value) {
					createdBy = value.createdBy;
					displayRoom = true;
				} else {
					changeToJoinPage("CreateScreen")
				}
			});
		localUserId = localStorage.getItem("userId");
		name = localStorage.getItem("name");
	});
</script>

<div class="container">
	<a href="https://www.letsdive.io/" target="_blank">
		<img src="/assets/svg/logo.svg" alt="company logo" class="companyLogo" />
	</a>
	<div class="innerContainer">
		<img class="logo" src={assets[process.env.APP_ENV][getParams("game")].logo} alt="logo" />
		{#if displayRoom}
			<div class="nameContainer">
				<span class="label">What would you like to call yourself today?</span>
				<input class="nameInput" type="text" bind:value={name} />
			</div>
			<div class="buttons">
				<!-- {#if localUserId === createdBy} -->
				<button class="button" use:cssVariables={{color}} on:click={copyLinkToCB}>{btnText}</button>
				<!-- {/if} -->
				<button class="button" class:disabled {disabled} use:cssVariables={{color}} on:click={goToGame}>Join the game</button>
			</div>
		{/if}
	</div>
</div>

<style>
	.container {
		width: calc(100% - 32px);
		height: 100%;
		margin: 0 16px;
		position: relative;
	}
	.innerContainer {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: absolute;
	}
	.companyLogo {
		top: 48px;
		left: 48px;
		position: absolute;
		z-index: 10;
	}
	.logo {
		width: 100%;
		height: auto;
		max-width: 429px;
		max-height: 175px;
		padding: 0;
		margin: 0 0 20px 0;
	}
	.nameContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.label {
		font-family: Manrope;
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
		line-height: 36px;
		text-align: center;
		color: #ffffff;
		margin-bottom: 10px;
	}
	.nameInput {
		width: 250px;
		height: 40px;
		background: #ffffff;
		border: 1px solid #dcdcdc;
		box-sizing: border-box;
		border-radius: 8px;

		font-family: Manrope;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 22px;
		padding: 0 8px;
		margin: 0;
	}
	.buttons {
		display: flex;
		justify-content: center;
	}
	.button {
		width: 218px;
		height: 52px;
		background: #ffffff;
		box-shadow: 0px 8px 0px rgba(102, 102, 102, 0.75);
		border-radius: 58px;
		font-family: Manrope;
		font-style: normal;
		font-weight: 800;
		font-size: 18px;
		line-height: 24px;
		text-align: center;
		color: var(--color);
		cursor: pointer;
		margin: 20px;
	}
	.disabled {
		background: #ccc;
		cursor: no-drop;
	}
	@media only screen and (max-width: 550px) {
		.companyLogo {
			top: 48px;
			left: 50%;
			transform: translate(50%);
		}
		.buttons {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;
			padding-top: 12px;
		}
		.button {
			margin: 12px;
		}
	}
</style>
